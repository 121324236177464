import logo from './logo.svg';
import './App.css';
import { useState } from "react";


function MyTest({ name }) {

    const [count, setCount] = useState(0);

    return (
        <div> 자 이제 해볼까? {name} </div>
    );
}

export default function App() {

    const name = '하하';

    console.log("시작부분");

    return (
        <div>
            <header className="App-header">
                {/*  오 아주 좋아...   */}
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Edit <code>src/App.js</code> and save to reload. dasfdsfa
                </p>
                <p>
                    {name}
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React</a>


                <input
                    placeholder='이제부터'
                    value={this}
                    onChange={
                        e => {
                            console.log('this----',e.target);
                        }


                    }/>

                <button onClick={
                    () =>{

                    }
                }>확인 dddadfsdafasdfasffd
                </button>

                <MyTest
                    name="이철규"
                />
                <MyTest/>
                <MyTest
                    name="이철"
                />
                <MyTest
                    name="이철규"
                />
            </header>
        </div>
    );
}